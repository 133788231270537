import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

export const toggles = [];

function fetchToggles() {
  if (toggles.length) {
    return SystemJS.import('feature-toggles!sofe').then(fui => fui.fetchFeatureToggles(...toggles));
  } else {
    return Promise.resolve();
  }
}

export const createTaxReturn = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: () =>
    fetchToggles().then(() => import('./create-organizer.component.js').then(mod => mod.default))
});
